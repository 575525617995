import { useEffect, useRef, useState } from "react";

import { messagesRestService } from 'services/rest/messages';

import "./styles.scss";

export const TitlePage = () => {
  const [myWsId, setMyWsId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);

  const messagesRef = useRef(messages);

  useEffect(() => {
    getAllMessages();
    subscribeOnMessages();
  }, []);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  const sendMessage = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    const data = {
      text: formProps.message,
    }

    messagesRestService.send(data)
      .then(res => {
        if (res.error) return console.error(res);
        setMessages([...messages, res]);
      })
      .catch(err => console.log(err));
  }

  const deleteMessage = (id) => {
    messagesRestService.delete(id)
      .then(res => {
        if (res.error) return console.error(res);
        setMessages(messages.filter(m => m._id !== id));
      })
      .catch(err => console.log(err));
  }

  const getAllMessages = () => {
    setLoading(true);
    messagesRestService.getAll()
      .then(res => {
        if (res.error) return console.error(res);
        setMessages(res.error ? [] : res);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }

  const subscribeOnMessages = () => {
    messagesRestService.subscribeOnMessages(data => {
      const messages = messagesRef.current;

      if (data.system) {
        if (data.message._wsId) setMyWsId(data.message._wsId);
        return console.log('System:', data.message);
      }

      if (data.message.deleted) {
        setMessages(messages.filter(m => m._id !== data.message._id));
        return;
      }

      setMessages([...messages, data.message]);
    });
  }

  const formatDate = (date) => {
    // day hour:minute
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const hours = d.getHours();
    const minutes = d.getMinutes();

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  return (
    <main className="container d-flex flex-column pb-1 flex-fill">
      <div className='row flex-fill'>
        <div className='col d-flex flex-column gap-1'>
          {messages.map(message => (
            <div
              key={message._id}
              className='message d-flex gap-1 justify-content-between align-items-center'>
              <div className='message__text'>
                {message.text}
              </div>

              <div className='d-flex align-items-center gap-1'>
                <div className='message__date'>
                  {formatDate(message.createdAt)}
                </div>
                <button
                  type="button"
                  className="btn"
                  onClick={() => deleteMessage(message._id)}>
                  <i className="bi bi-trash"></i>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <form
        className="row"
        onSubmit={e => sendMessage(e)}>
        <div className="col">
          <div className="form-group">
            <input type="text" name='message' />
            <input type="submit"/>
          </div>
        </div>
      </form>
    </main>
  );
}

export default TitlePage;
