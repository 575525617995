import { api } from '../api';

import { MessageDto } from './message.dto';

export const messagesRestService = {
  getAll: () => {
    return api.get('/messages')
      .then((response) => {
        return response.map(message => new MessageDto(message))
      });
  },
  send: (product) => {
    return api.post('/messages', product)
      .then((response) => {
        return response;
      })
  },
  delete: (id) => {
    return api.remove(`/messages/${id}`)
      .then((response) => {
        return response;
      })
  },
  subscribeOnMessages: (callback) => {
    return api.wsSubscribe('/messages', callback);
  }
}
