import "./styles.scss";

export const Header = () => {
  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col">
            ws chat v0.0.1
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
