const isDev = process.env.NODE_ENV === 'development';

const _client = (path, body, method = 'get', params = {}) => {
  if (isDev) {
    console.log('Request', getUrl(path));
  }

  return fetch(getUrl(path),
    {
      credentials: 'include',
      method: method.toUpperCase(),
      body: body && JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      },
      ...params
    }
  ).then(resp => resp.json())
}

export const get = (path, params) => {
  if (params && Object.keys(params).length) {
    const keys = Object.keys(params);

    path += '?' + keys
      .map(key => (`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`))
      .join('&');
  }

  return _client(path, null, 'GET', params);
}

export const post = (path, body, params) => {
  return _client(path, body, 'POST', params);
}

export const update = (path, body, params) => {
  return _client(path, body, 'PUT', params);
}

export const remove = (path, params) => {
  return _client(path, params, 'DELETE');
}

export const wsSubscribe = (path, callback) => {
  const ws = new WebSocket(getUrl(path, isDev ? 'ws' : 'wss'));

  ws.onmessage = (event) => {
    callback(JSON.parse(event.data));
  }

  return ws;
}

export const api = {
  get,
  post,
  update,
  remove,
  wsSubscribe
}

function getUrl(path = '', protocol = isDev ? 'http' : 'https') {
  // Smth like: http://localhost
  let url = (protocol ? protocol + ':' : location.protocol)
    + '//'
    + location.hostname;

  // Smth like: http://localhost:3001/api
  if (isDev) {
    url += ':'
      + process.env.REACT_APP_BE_PORT
      + process.env.REACT_APP_BE_PREFIX
      + path;
  } else if (protocol === 'wss') {
    url += '/wss/' + path;
  } else {
    url += process.env.REACT_APP_BE_PREFIX + path;
  }

  return url;
}
