export class MessageDto {
  _id;
  text;
  clientId; // only BE sends this field
  createdAt;

  constructor(message) {
    Object.keys(message || {}).forEach(key => {
      this[key] = message[key];
    });
  }
}
